@tailwind base;
@tailwind components;
@tailwind utilities;

.shrinking-legend {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    background: rgb(3 7 18);
    color: #fff;
    padding: 10px;
    text-align: center;
    opacity: .25;
    transition: opacity 0.35s ease-in-out;
}

.carousel:hover .shrinking-legend, .carousel:focus .shrinking-legend, .slide:hover .shrinking-legend, .slide:focus .shrinking-legend {
    opacity: 1;
}

.size-label {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(-50%);
    border-radius: 10px;
    background: rgb(3 7 18);
    color: #fff;
    padding: 10px;
    text-align: center;
    opacity: .25;
    transition: opacity 0.35s ease-in-out;
}

.carousel:hover .size-label, .carousel:focus .size-label, .slide:hover .size-label, .slide:focus .size-label {
    opacity: .65;
}