@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --animation-duration: 300ms;
}

.viewAllText {
    visibility: hidden;
}

.viewAllContainer {
    user-select: none;
    cursor: pointer;
    transform: translateX(-80%);
    transition: transform var(--animation-duration) ease-in-out;
}

.viewAllContainer:hover {
    transform: translateX(0);
}

.viewAllContainer:hover > .viewAllText {
    animation: showText var(--animation-duration) forwards var(--animation-duration)
}

@keyframes showText {
    to {
        visibility: visible;
        opacity: 1;
    }
}