@tailwind base;
@tailwind components;
@tailwind utilities;


.timeline-item-container::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    left: 0;
    top: 0;
    transform: translate(-50%, 50%);
    border-radius: 50%;
}
