@tailwind base;
@tailwind components;
@tailwind utilities;

select {
    padding: 5px;
    font-size: 16px;
    outline: none; /* Remove the default focus outline */
    border: none; /* Normal border */
}
select:focus {
    border: none;
    box-shadow: none; /* Remove any box shadow that may appear */
}